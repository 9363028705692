var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":(this.itemId === 'new' ? 'Link' : 'Save'),"title":(this.itemId === 'new' ? 'New' : 'Edit') + ' Team Member'},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('TeamMemberForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,954445673),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('YesNoDialog',{attrs:{"title":"Delete team member","text":"Are you really want to delete the team member?"},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v("Team")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v("New Team Member")])],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":!_vm.isUserAdmin()},on:{"change":function($event){item.admin ? _vm.grantAdmin(item.id) : _vm.refuseAdmin(item.id)}},model:{value:(item.admin),callback:function ($$v) {_vm.$set(item, "admin", $$v)},expression:"item.admin"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.isUserAdmin()},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,433215650)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }